<template>
  <add-cash-collection-dialog
    v-if="isReady"
    :value="true"
    :anonymous-organization-name="organizationName"
    :anonymous-collection-name="collectionName"
    @save-cash-collection="saveCashPayment"
  ></add-cash-collection-dialog>
</template>

<script>
import AddCashCollectionDialog from "@/components/collection/AddCashCollectionDialog.vue"
import { SET_ERROR_MESSAGE, SET_SNACKBAR_MESSAGE } from "@/modules/common/store/mutation-types"

export default {
  name: "CashPaymentSubmission",
  components: { AddCashCollectionDialog },
  data () {
    return {
      organizationName: null,
      collectionName: null,
    }
  },
  props: {
    token: {
      type: String,
      required: true
    }
  },
  computed: {
    isReady () {
      return !!this.organizationName && !!this.collectionName
    }
  },
  async mounted () {
    const res = await this.$store.getters.restApi.get(`collection_plan/cash_payment/${this.token}/`)
    this.organizationName = res.data.organization_name
    this.collectionName = res.data.name
    // TODO - FIXME - Add currency
  },
  methods: {
    async saveCashPayment ({ amount }) {
      try {
        await this.$store.getters.restApi.post(`collection_plan/cash_payment/${this.token}/`, {
          amount,
          currency: 'EUR'
        })
        await this.$store.commit(
          SET_SNACKBAR_MESSAGE,
          { message: `Barsammlung erfolgreich eingetragen` },
          { root: true }
        )
      } catch (e) {
        await this.$store.commit(
          SET_ERROR_MESSAGE,
          { message: e.message },
          { root: true }
        )
      }
    }
  }
}
</script>
